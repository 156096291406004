import { useSelector } from "react-redux"
import Spinner from '../components/base/Spinner'
import Header from "../components/base/Header"
import DonutWrapper from '../components/Cyberdash/DonutWrapper'
import WorldMap from '../components/ThreatDashboard/WorldMap'
import CVETable from '../components/ThreatDashboard/CVETable'
import TreeMap from "../components/ThreatDashboard/Treemap"
import Histogram from "../components/ThreatDashboard/Histogram"
import Disclaimer from "../components/base/Disclaimer"
import { useEffect, useState } from "react"

const ThreatBriefs = () => {
  const { threat_briefs, threat_dashboard, isLoading, isSuccess } = useSelector(
    (state) => state.data
  );
  // const [malwareData, setMalwareData] = useState({})

  // useEffect(() => {
  //   const fetchMalwareData = async () => {
  //     const response = await fetch("/malware_data.json")
  //     const malware_data = await response.json()
  //     setMalwareData(malware_data)
  //   }

  //   fetchMalwareData()
  // })


  if (isLoading) {
    return <Spinner />;
  }

  if (isSuccess && threat_dashboard && threat_briefs) {
    return (
      <main>
        <Header text={"Attacks Dashboard"} />
        <div className="Grid">
          <WorldMap data={threat_dashboard['1Map']} />
          {/* <TreeMap data={threat_dashboard['3Bar']} /> */}
          <TreeMap data={threat_dashboard['4Bar']} />
          <DonutWrapper data={threat_dashboard["1Donut"]} />
          <Histogram data={threat_dashboard['1WordCloud']} interval={1000} />
          <Histogram data={threat_dashboard['2WordCloud']} interval={100} />
          <DonutWrapper data={threat_dashboard["2Donut"]} />
          <DonutWrapper data={threat_dashboard['8Donut']} />
          <CVETable data={threat_dashboard['2Table']} />
          <Disclaimer />
        </div>
      </main>
    )
  }
}

export default ThreatBriefs;
